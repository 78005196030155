import React from "react"
import styled from "styled-components"

import { SEO } from "../components"
import { Branches, GetInTouch } from "../sections/contact-us"

const ContactUs: React.FC = () => {
  return (
    <PageWrapper>
      <SEO
        title="Contact Us"
        description="Contact Workerslife for personalised financial solutions including life insurance, funeral benefits, and short-term insurance. Let us help secure your future."
      />
      <GetInTouch />
      <Branches />
    </PageWrapper>
  )
}

export default ContactUs

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 85px;
`
